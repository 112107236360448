import $ from 'jquery';

$.addInit(function(context) {
	var base = '.b-poster';
	$(base, context).each(function(){
		var component = $(this);
		var slideList = component.find(base + '-slideList');
		var slides = component.find(base + '-slide');
		var prev = component.find(base + '-prev');
		var next = component.find(base + '-next');
		var indicatorList = component.find(base + '-indicatorList');
		var indicators = $();

		var activeIndex = 0;
		var interval = +component.data('interval');
		var timeout = null;

		slides.each(function(i){
			var indicator = $('<a>', {
				class: base.substring(1) + '-indicator',
				dataNum: i + 1
			});
			indicators.push(indicator.appendTo(indicatorList).get(0));
		});

		function setActive(index) {
			index = (index + slides.length) % slides.length;

			if (interval) {
				clearTimeout(timeout);
				timeout = setTimeout(setActive.bind(this, index + 1), interval);
			}

			slides.eq(activeIndex).add(indicators.eq(activeIndex)).removeClass('m-active');
			slides.eq(index).add(indicators.eq(index)).addClass('m-active');

			slideList.css({left: index * -100 + '%'});

			activeIndex = index;
		}

		prev.click(function(e){
			e.preventDefault();
			setActive(activeIndex - 1);
		});

		next.click(function(e){
			e.preventDefault();
			setActive(activeIndex + 1);
		});

		indicators.click(function(e){
			e.preventDefault();
			setActive(indicators.index(this));
		});

		$(window).on('resize', function(){
			setActive(activeIndex);
		});

		setActive(activeIndex);
	});
});
