import $ from 'jquery';

$.addInit(function(context){
	var base = '.i-stopWheel';
	
	$(base, context).each(function(){
		var component = $(this);

		component.on('wheel', function(e) {
			// if (this.scrollTop !== (e.originalEvent.deltaY > 0 ? this.scrollHeight - this.clientHeight : 0)) {
			// 	e.stopPropagation();
			// }

			if (e.originalEvent.deltaY < 0 && this.scrollTop > 0) {
				e.stopPropagation();
			} else if (e.originalEvent.deltaY > 0 && this.scrollTop < this.scrollHeight - this.clientHeight) {
				e.stopPropagation();
			}
		});

		component.on('touchstart', function(e) {
			if(e.originalEvent.touches.length > 1)
				return;
	
			let lastY = e.originalEvent.touches[0].clientY;
	
			function touchmove(e) {
				let deltaY = lastY - e.originalEvent.touches[0].pageY;
				if (deltaY < 0 && this.scrollTop > 0) {
					e.stopPropagation();
				} else if (deltaY > 0 && this.scrollTop < this.scrollHeight - this.clientHeight) {
					e.stopPropagation();
				}
			}
			component.on('touchmove', touchmove);
			component.one('touchend', function() {
				component.off('touchmove', touchmove);
			});
		});
	});
});