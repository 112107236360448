import breakpoint from '../scripts/breakpoint';
import {TimelineMax} from 'gsap';
import $ from 'jquery';


function gotoSlide(data) {
	let currentDesktopSlide = $('[data-slide-desktop='+data.from+']');
	let newDesktopSlide = $('[data-slide-desktop='+data.to+']');

	let currentMobSlide = $('[data-slide-mobile='+data.from+']');
	let newMobSlide = $('[data-slide-mobile='+data.to+']');

	let timelineZIndex = null;
	let timelineOpacity = null;
	let timelineScrollDown = null;
	let timelineScrollUp = null;

	function removeTimeline() {
		if (timelineZIndex) {
			timelineZIndex.remove();
		}

		if (timelineOpacity) {
			timelineOpacity.remove();
		}

		if (timelineScrollDown) {
			timelineScrollDown.remove();
		}

		if (timelineScrollUp) {
			timelineScrollUp.remove();
		}
	}

	function removeAttrs() {
		currentDesktopSlide.removeAttr('style');
		newDesktopSlide.removeAttr('style');
		currentMobSlide.removeAttr('style');
		newMobSlide.removeAttr('style');
	}

	breakpoint(null, 'lg')
		.onmatch(function(){
			removeTimeline();
			removeAttrs();
		})
		.onmismatch(function(){
			removeTimeline();
			removeAttrs();

			timelineZIndex = new TimelineMax()
				.set(currentDesktopSlide,{zIndex:20})
				.set(newDesktopSlide,{zIndex:30});

			timelineScrollDown = new TimelineMax();
			timelineScrollUp = new TimelineMax();

			if (currentDesktopSlide.index() < newDesktopSlide.index()) {
				timelineScrollDown
					.fromTo(currentDesktopSlide,0.75,{y:'0%',opacity: 1},{y:'-100%',opacity: 0, ease: Power4.easeInOut}, 0.65)
					.fromTo(newDesktopSlide,0.75,{y:'100%',opacity: 1},{y:'0%',opacity: 1, ease: Power1.easeInOut}, 0)

			} else {
				timelineScrollUp
					.fromTo(currentDesktopSlide,0.75,{y:'0%',opacity: 1, ease: Power4.easeInOut},{y:'100%',opacity: 0}, 0)
					.fromTo(newDesktopSlide,0.75,{y:'0',opacity: 1, ease: Power1.easeInOut},{y:'0%',opacity: 1}, 0)
			}

			var head = $('.b-head');

			if (newDesktopSlide.index() > 0) {
				head.addClass('m-active');
			} else {
				head.removeClass('m-active');
			}
	});
}

function initSlides() {
	let totalSlides = 1;
	let activeSlide = 1;
	let canGo = 1;
	let delay = 1200;

	breakpoint(null, 'lg')
		.onmatch(function(){

		})
		.onmismatch(function(){
			totalSlides = $('[data-slide-desktop]').length;
			setTimeout(function() {showSlide(1)}, 780);
		});

	function showSlide(newslide) {
		if(!canGo)
			return;

		if (newslide > totalSlides || newslide < 1)
			return;

		canGo = false;

		gotoSlide({from: activeSlide, to:newslide});

		activeSlide = newslide;
		setTimeout(function() {
			canGo = true;
		}, delay);
	}

	$(window).on('wheel', function(e) {
		let direction = e.originalEvent.deltaY > 0 ? 1 :-1;

		showSlide(activeSlide + direction);
	});

	$(window).on('touchstart', function(e) {
		if(!canGo || e.originalEvent.touches.length > 1)
			return;

		let lastY = e.originalEvent.touches[0].clientY;

		function touchmove(e) {
			let deltaY = lastY - e.originalEvent.touches[0].clientY;
			let direction = deltaY > 0 ? 1 :-1;

			if (Math.abs(deltaY) > 10) {
				$(window).off('touchmove', touchmove);
				showSlide(activeSlide + direction);
			}
		}

		$(window).on('touchmove', touchmove);
		$(window).one('touchend', function() {
			$(window).off('touchmove', touchmove);
		});
	});

	
	$('.i-fullScrollTo').on('click', function(e) {
		e.preventDefault();
		showSlide($(this).data('slide'));
	});
}

initSlides();