import breakpoint from '../scripts/breakpoint';
import shared from '../scripts/shared';
import $ from 'jquery';

$.addInit(function(context){
	var base = '.b-head';
	$(base, context).each(function(){
		var component = $(this);
		var poster = $('[data-poster]');
		var posterHeight;

		shared.headerHeight = component.outerHeight();

		function getPosterHeight() {
			posterHeight = poster.height();
			return posterHeight;
		}

		function scrollOnDesktop() {
			var currentScrollPos = $(this).scrollTop();
			component.toggleClass('m-active',  currentScrollPos >= getPosterHeight() );
		}

		$(window).on('scroll', scrollOnDesktop);
		$(window).on('resize', getPosterHeight);

		if (poster == 'hero') {
			breakpoint(null, 'lg')
				.onmatch(function(){
					$(window).on('scroll', scrollOnDesktop);
					$(window).on('resize', getPosterHeight);
				})
				.onmismatch(function(){
					$(window).off('scroll', scrollOnDesktop);
					$(window).off('resize', getPosterHeight);
				});
		}
	});
});