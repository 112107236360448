import $ from 'jquery';
import autosize from 'autosize';

$.addInit(function(context) {
	var base = '.b-form';
	$(base, context).validity({
		output: function(input, message) {
			var field = $(input).not('button, [type=submit]').closest(base + '-field');
			field.toggleClass('m-error', !!message);
			field.find('.i-form-checkbox').toggleClass('m-error', !!message);
			field.find(base + '-error').text(message);
		}
	});

	var input = $(base).find('input, textarea');

	function labelEffects(input) {
		[].slice.call(input).forEach(function(inputEl) {
			if (inputEl.value.trim() !== '') {
				$(inputEl.parentNode).addClass('m-active');
			}

			inputEl.addEventListener('focus', onInputFocus);
			inputEl.addEventListener('blur', onInputBlur);
		} );

		function onInputFocus(e) {
			$(e.target.parentNode).addClass('m-active');
		}

		function onInputBlur(e) {
			if (e.target.value.trim() === '') {
				$(e.target.parentNode).removeClass('m-active');
			}
		}
	}

	labelEffects(input);

	$(base, context).each(function() {
		var component = $(this);
		var textarea = component.find('textarea');
		autosize(textarea);
	});
});
