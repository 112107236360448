import breakpoint from '../scripts/breakpoint';
import $ from 'jquery';

$.addInit(function(context){
	var base = '.b-hero';
	$(base, context).each(function(){
		var component = $(this);

		component.addClass('m-active');

		var leftLink = component.find('.i-hero-linkLeft');
		var rightLink = component.find('.i-hero-linkRight');

		function hoverLink(el, className) {
			el.on('mouseenter', function() {
				component.addClass(className)
			});
	
			el.on('mouseleave', function() {
				component.removeClass(className)
			});
		}

		breakpoint(null, 'lg')
			.onmatch(function(){

			})
			.onmismatch(function(){
				hoverLink(leftLink, 'm-animate-piramide');
				hoverLink(rightLink, 'm-animate-line');
			});
	});
});