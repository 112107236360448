import breakpoint from '../scripts/breakpoint';
import $ from 'jquery';

$.addInit(function(context){
	var base = '.b-headAddress';
	var desktop = $('.i-headAddress-desktop');
	var mobile = $('.i-headAddress-mobile');
	$(base, context).each(function(){
		var component = $(this);

		breakpoint('xs', 'md')
			.onmatch(function(){
				component.appendTo(mobile);
			})
			.onmismatch(function(){
				component.appendTo(desktop);
			});
	});
});
