import $ from 'jquery';
import bodyScroll from '../scripts/bodyScroll';

var base = '.b-modal';

export function resetHash(hash) {
	if (location.hash === hash) {
		history.replaceState({}, document.title, location.pathname + location.search);
	}
}

export function showModal(target, url) {
	if (!target || target === '#')
		return;

	target = $(target);

	var component = target.closest(base);
	if (!component.length)
		return;

	component.data('hash', '#' + target.prop('id'));
	component.trigger('show');

	if (!url || url[0] === '#') {
		url = target.data('load');
	}

	if (url && url[0] !== '#') {
		target.addClass('m-loading').load(url, function(data) {
			target.removeClass('m-loading').execInit();
		});
	}
}

$.addInit(function(context) {
	$(base, context).each(function() {
		var component = $(this);

		component.on('show', function() {
			component.addClass('m-show');
			$(document.body).addClass('m-fixedStyles');
			bodyScroll.disable(component.get(0), {reserveScrollBarGap: true});
			component.transitionDuration(function() {
				component.trigger('afterShow');
			});
		});

		component.on('close', function() {
			component.removeClass('m-show').transitionDuration(function() {
				bodyScroll.enable(component.get(0));
				$(document.body).removeClass('m-fixedStyles');
				component.trigger('afterClose');
			});

			resetHash(component.data('hash'));
		});

		component.on('click', function(e) {
			if (e.target === this) {
				component.trigger('close');
			}
		});

		component.on('click', '.i-modal-close', function(e) {
			e.preventDefault();

			component.trigger('close');
		});
	});

	if (context === document) {
		$(document).on('click', '.i-modal-link', function(e) {
			e.preventDefault();
			var link = $(this);
			showModal(link.data('target') || link.prop('hash'), link.data('load') || link.attr('href'));
		});

		$(window).on('hashchange', function(e) {
			showModal(location.hash);
		});

		showModal(location.hash);
	}
});
