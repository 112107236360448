var i18n = {
	locale: document.documentElement.lang || 'ru',
	messages: {
		'ru': {
			'Invalid value': 'Некорректное значение',
			'Value missing': 'Заполните поле',
			'Unknown error': 'Неизвестная ошибка',
		},
		'en-US': {
			'Invalid value': 'Invalid value',
			'Value missing': 'Fill in the field',
			'Unknown error': 'Unknown error',
		},
		'fi-FI': {
			'Invalid value': 'Virheellinen arvo',
			'Value missing': 'Arvo puuttuu',
			'Unknown error': 'Tuntematon virhe',
		}
	},
	gettext: function(text) {
		var lang = i18n.messages[i18n.locale] ? i18n.locale : 'ru';

		return i18n.messages[lang][text] || text;
	},
};


export default i18n;
