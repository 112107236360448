import $ from 'jquery';

$.addInit(function(context){
	$('.b-map', context).each(function(){
		var component = $(this);
		var componentMap = component.find('.b-map-map');
		var mapZoom = componentMap.data('zoom') || 15;
		var mapCenter = componentMap.data('center');

		function initMap() {
			var map = new google.maps.Map(componentMap[0], {
				center: {lat: mapCenter[0], lng: mapCenter[1]},
				zoom: mapZoom,
				disableDefaultUI: true,
				styles: [
					{
						"featureType": "administrative",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#444444"
							}
						]
					},
					{
						"featureType": "landscape",
						"elementType": "all",
						"stylers": [
							{
								"color": "#f2f2f2"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels.text",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": 45
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "all",
						"stylers": [
							{
								"color": "#dbdbdb"
							},
							{
								"visibility": "on"
							}
						]
					}
				]
			});

			var placemarks = component.find('.b-map-balloonContent').each(function(i, el) {
				var data = $(el).data();

				return  new google.maps.Marker({
					position: {lat: data.coord[0], lng: data.coord[1]},
					map: map,
					icon: data.icon,
				});
			});
		}

		google.maps.event.addDomListener(window, 'load', initMap);
	});
});
