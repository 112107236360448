import i18n from './i18n';
import $ from 'jquery';

$.validity = {
	defaults: {
		errors: {
			badInput: i18n.gettext('Invalid value'),
			customError: i18n.gettext('Invalid value'),
			patternMismatch: i18n.gettext('Invalid value'),
			rangeOverflow: i18n.gettext('Invalid value'),
			rangeUnderflow: i18n.gettext('Invalid value'),
			stepMismatch: i18n.gettext('Invalid value'),
			tooLong: i18n.gettext('Invalid value'),
			tooShort: i18n.gettext('Invalid value'),
			typeMismatch: i18n.gettext('Invalid value'),
			valueMissing: i18n.gettext('Value missing'),
		},
		unknownError: i18n.gettext('Unknown error'),
		output: function(input, message) {
			console.log('validity.output', input, message);
		},
		getMessage: function(input){
			if (input.validity.valid)
				return '';

			var customErrors = $(input).data('errors') || {};

			for (var error in this.errors) {
				if (input.validity[error])
					return i18n.gettext(customErrors[error]) || this.errors[error];
			}

			return this.unknownError;
		}
	}
};

$.fn.validity = function(options) {
	options = $.extend({}, $.validity.defaults, options);

	return this.each(function(){
		var component = $(this);
		var form = component.closest('form');
		var elements = $(form.prop('elements'));

		var tel = form.find('input[type="tel"]');
		tel.prop('pattern', '[-+() \\d]*');

		setTimeout(function() {
			form.on('change input', function(e){
				options.output(e.target, options.getMessage(e.target));
			});
		});

		form.on('submit', function(e){
			if (this.checkValidity())
				return;

			e.preventDefault();
			e.stopPropagation();

			$(form.prop('elements')).not('.select2-search__field').trigger('input');
		});

		form.attr('novalidate', true);
	});
}

export default $.validity;
